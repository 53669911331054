import { ModalProps, modalActions } from '@src/store/modules/modal'
import { useAppDispatch, useAppSelector } from './redux'

const useReduxModal = () => {
  const dispatch = useAppDispatch()
  const modals = useAppSelector((state) => state.modal)

  const openModal = (props: ModalProps) => {
    const key = Math.random().toString(36).substring(2)
    const nextProps = {
      key,
      close: () => dispatch(modalActions.setState([...modals])),
      ...props,
    }
    dispatch(modalActions.setState([...modals].concat(nextProps)))
  }
  const closeModal = () => {
    dispatch(modalActions.setState([]))
  }

  return { openModal, closeModal }
}

export default useReduxModal
