import styled from 'styled-components'

// 확인 모달에 사용하는 column wrapper
export const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 440px;
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
  > p {
    ${({ theme }) => theme.wev_font.sBold_16};
  }
`
export const ModalIcon = styled.div<{ color: string; bg_color: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  position: relative;
  > div {
    width: 60px;
    height: 60px;
    border-radius: 90%;
    background-color: ${({ bg_color }) => bg_color};
    animation: loading 2s linear infinite;
  }
  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: ${({ color }) => color};
  }
  @keyframes loading {
    0%,
    100% {
      opacity: 0.5;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`
export const Title = styled.p`
  ${({ theme }) => theme.wev_font.sBold_18};
`

export const SubTitle = styled.p`
  ${({ theme }) => theme.wev_font.Medium_16};
  margin-top: 25px;
`
export const Desc = styled.p`
  ${({ theme }) => theme.wev_font.Regular_14};
  color: ${({ theme }) => theme.wev_color.hint};
  margin-top: 5px;
`
