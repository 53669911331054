import React from 'react'
import AppImg3 from '../../../public/images/main/APP_IMG3.png'
import Image from 'next/image'

const App = () => {
  return (
    <div className="flex flex-col md:flex-row overflow-hidden items-center ml-1 justify-center h-screen">
      <div className="flex flex-col sm:flex-row w-1/3 justify-center" id="slide-in">
        <Image src={AppImg3} width={280} height={540} alt="위브이 앱 그림" />
      </div>
      <div className="flex flex-col ml-0 md:ml-10 mt-5 md:mt-0 text-center">
        <div
          id="title1"
          className="pr-3 text-transparent text-2xl sm:text-6xl bg-clip-text bg-gradient-to-r from-fontgreen to-fontblue font-Orbitron font-extrabold italic text-gray-800"
        >
          WEV
        </div>
        <div
          id="title2"
          className="pr-3 text-transparent text-2xl sm:text-6xl bg-clip-text bg-gradient-to-r from-fontgreen to-fontblue font-Orbitron font-extrabold italic text-gray-800"
        >
          APPlication
        </div>
        <div id="sub-title1" className="mt-2 sm:mt-8 text-md sm:text-lg font-NanumSquare font-bold text-zinc-500">
          내 손 안의 또 다른 충전소, 위브이 앱!
        </div>
        <div id="sub-title2" className="text-md sm:text-lg font-NanumSquare font-bold text-zinc-500 mb-5">
          지금 앱스토어와 플레이스토어에서 다운로드 하세요.
        </div>
        <div className="flex flex-col items-center justify-center mt-5">
          <button
            id="sub-title3"
            className="w-full md:w-2/3 mb-5 rounded-md py-3 text-md sm:text-lg font-NanumSquare font-extrabold text-white bg-gradient-to-r from-fontgreen to-fontblue"
            type="button"
            onClick={() => window.open('https://apps.apple.com/app/1673610987')}
          >
            APP STORE
          </button>
          <button
            id="sub-title3"
            className="w-full md:w-2/3 rounded-md py-3 text-md sm:text-lg font-NanumSquare font-extrabold text-white bg-gradient-to-r from-fontgreen to-fontblue"
            type="button"
            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.elelectric.wev')}
          >
            PLAY STORE
          </button>
        </div>
      </div>
    </div>
  )
}

export default App
