import { css } from 'styled-components'

export const pageCTAButton = css`
  height: 57px;
  width: 100%;
  border-radius: 10px;
  padding: 20px 30px;
  ${({ theme }) => theme.wev_font.sBold_14}
  @media ${({ theme }) => theme.device.mobile} {
    height: 52px;
  }
`
export const modalButton = css`
  height: 59px;
  width: 100%;
  border-radius: 5px;
  padding: 20px 30px;
  ${({ theme }) => theme.wev_font.Medium_14}
  @media ${({ theme }) => theme.device.mobile} {
    height: 52px;
  }
`

/*  ---------- color --------------- */
// 기본 버튼 컬러
export const defaultButtonColor = css`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.hint};
`
export const colorLineButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.sub_btn_hover};
  border: 1px solid ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.primary};
`
// 색상이 채워진 버튼 컬러
export const filledButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.white};
`
// gray 라인만 있는 버튼 컬러
export const lineButtonColor = css`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.hint};
`
// gray 배경의 버튼 컬러
export const grayButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.text};
`

// disable 버튼 컬러
export const disabledButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.info_bg};
  color: ${({ theme }) => theme.wev_color.hint};
`
// delete 버튼 컬러
export const deletedButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.delete_hover};
  color: ${({ theme }) => theme.wev_color.delete};
`

export const commonButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.white};
  :disabled {
    ${disabledButtonColor}
  }
`
