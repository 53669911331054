import React, { ReactNode } from 'react'
import useReduxModal from '@src/hooks/useModal'
import { BusinessBtn } from '../../button'
import { Check_Circle_F, Sd_Card_Alert_F, Warning } from '@src/assets/icons'
import * as S from './AlertModal.styled'
interface IProps {
  type: 'error' | 'warning' | 'success' | 'custom'
  title?: string
  subTitle?: string
  desc?: string
  confirmFunc?: any
  children?: ReactNode
}
const AlertModal = ({ type = 'error', title, subTitle, desc, confirmFunc, children }: IProps) => {
  const modal = useReduxModal()
  if (type === 'error') {
    return (
      <S.ConfirmWrapper>
        <S.ModalIcon color="#E91212" bg_color="#FDE6E6">
          <div />
          <span>
            <Warning width={24} height={24} style={{ fill: '#E91212' }} />
          </span>
        </S.ModalIcon>
        <S.Content>
          <S.Title>{title}</S.Title>
          {subTitle ? <S.SubTitle>{subTitle}</S.SubTitle> : null}
          {desc ? <S.Desc>{desc}</S.Desc> : null}
        </S.Content>
        <BusinessBtn
          useType="Modal"
          color="Gray"
          onClick={() => {
            modal.closeModal()
            confirmFunc ? confirmFunc() : null
          }}
        >
          확인
        </BusinessBtn>
      </S.ConfirmWrapper>
    )
  } else if (type === 'warning') {
    return (
      <S.ConfirmWrapper>
        <S.ModalIcon color="#FFCC18" bg_color="#FFF5D3">
          <div />
          <span>
            <Sd_Card_Alert_F width={24} height={24} style={{ fill: '#FFCC18' }} />
          </span>
        </S.ModalIcon>
        <S.Content>
          <S.Title>{title}</S.Title>
          {subTitle ? <S.SubTitle>{subTitle}</S.SubTitle> : null}
          {desc ? <S.Desc>{desc}</S.Desc> : null}
        </S.Content>

        <BusinessBtn
          useType="Modal"
          color="Gray"
          onClick={() => {
            modal.closeModal()
            confirmFunc ? confirmFunc() : null
          }}
        >
          확인
        </BusinessBtn>
      </S.ConfirmWrapper>
    )
  } else if (type === 'custom') {
    return (
      <S.ConfirmWrapper>
        {children}
        <BusinessBtn
          useType="Modal"
          color="Filled"
          onClick={() => {
            modal.closeModal()
            confirmFunc ? confirmFunc() : null
          }}
        >
          확인
        </BusinessBtn>
      </S.ConfirmWrapper>
    )
  } else {
    return (
      <S.ConfirmWrapper>
        {children ? (
          children
        ) : (
          <S.ModalIcon color="#583EEE" bg_color="#F9F8FF">
            <div />
            <span>
              <Check_Circle_F viewBox="0 0 20 20" width={20} height={20} fill="#583EEE" />
            </span>
          </S.ModalIcon>
        )}
        <S.Content>
          <S.Title>{title}</S.Title>
          {subTitle ? <S.SubTitle>{subTitle}</S.SubTitle> : null}
          {desc ? <S.Desc>{desc}</S.Desc> : null}
        </S.Content>

        <BusinessBtn
          useType="Modal"
          color="Filled"
          onClick={() => {
            modal.closeModal()
            confirmFunc ? confirmFunc() : null
          }}
        >
          확인
        </BusinessBtn>
      </S.ConfirmWrapper>
    )
  }
}

export default AlertModal
