import { ComponentPropsWithoutRef, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import {
  colorLineButtonColor,
  commonButtonStyles,
  deletedButtonColor,
  filledButtonColor,
  grayButtonColor,
  lineButtonColor,
  modalButton,
  pageCTAButton,
} from './ButtonStyle'

type ButtonType = 'Modal' | 'CTA'
type ButtonColor = 'Filled' | 'Line' | 'Gray' | 'Delete' | 'ColorLine'
export interface IBusinessBtn extends ComponentPropsWithoutRef<'button'> {
  useType?: ButtonType
  color?: ButtonColor
  children?: ReactNode
}

const BusinessBtn = ({ useType = 'CTA', color = 'Line', children, ...props }: IBusinessBtn) => (
  <StyledButton useType={useType} color={color} {...props}>
    {children}
  </StyledButton>
)

const StyledButton = styled.button<{ useType: ButtonType; color: ButtonColor }>`
  border-radius: 30px;
  ${commonButtonStyles}

  /* Size Theme 속성 */
  ${({ useType }) => css`
    ${useType === 'Modal' && modalButton}
    ${useType === 'CTA' && pageCTAButton}
  `}
  ${({ color }) => css`
    ${color === 'Line' && lineButtonColor}
    ${color === 'ColorLine' && colorLineButtonColor}
    ${color === 'Filled' && filledButtonColor}
    ${color === 'Gray' && grayButtonColor}
    ${color === 'Delete' && deletedButtonColor}
  `}
`

export default BusinessBtn
